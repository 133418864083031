import { workstate } from '.';
import { apiAxios } from 'src/util';

export const lockLoan = (loanGuid) => async (dispatch) => {
  try {
    const res = await apiAxios.post(`loans/${loanGuid}/locks`);
    dispatch({
      type: 'set_loan_locked',
      data: { isLockedByOther: false, loanGuid, ...res.data },
    });
  } catch (e) {
    const res = e.response;
    dispatch({
      type: 'set_loan_locked',
      data:
        res?.status === 409
          ? { isLockedByOther: true, loanGuid }
          : {
              isLockedByOther: false,
              error:
                res?.data?.messages?.[0] ??
                `Could not lock loan (Error status ${res?.status})`,
              loanGuid,
            },
    });
  }
};

export const refreshLoanLock = (loanGuid) => async () => {
  return await apiAxios.patch(`loans/${loanGuid}/locks`);
};

export const deleteLoanLock = (loanGuid) => async () => {
  return await apiAxios.delete(`loans/${loanGuid}/locks`);
};

export const submitAppAndAddTasks = async (loanGuid, applicationGuid, data) => {
  return await apiAxios.post(
    `loans/${loanGuid}/applications/${applicationGuid}/submit`,
    data
  );
};

export const sendFlaxasLead = async (loanGuid) => {
  return await apiAxios.post(`loans/${loanGuid}/applications/flaxas`);
};

export const getFinalCheckStatus =
  ({ loanGuid, applicationGuid }) =>
  (dispatch) => {
    return workstate(dispatch, 'final-check-status', async () => {
      const res = await apiAxios.get(
        `loans/${loanGuid}/applications/${applicationGuid}/missing-requirements`
      );
      return res.data;
    });
  };
